html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Josefin Sans, sans-serif;
  font-weight: 200;
}

.DTN0qG_renderCanvas {
  width: 100%;
  height: 100%;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.DTN0qG_topLogo {
  color: #000;
  min-width: 80%;
  padding-top: 25px;
  padding-left: 25px;
  font-size: 80px;
}

a, a:visited {
  color: #fff;
}

.DTN0qG_cover {
  width: 100%;
  min-height: 100%;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  position: absolute;
}

.DTN0qG_gameCover {
  width: 100%;
  min-height: 100%;
  vertical-align: middle;
  background-color: #00000080;
  margin: 0;
  padding: 0;
  position: absolute;
}

#DTN0qG_header-bar {
  width: 100%;
  height: 120px;
  background-color: #fff;
  justify-content: space-between;
  display: flex;
}

.DTN0qG_colored {
  width: 8px;
  height: 8px;
  border: 1px solid gray;
  display: inline-block;
}

.DTN0qG_clay {
  background-color: #a86d00;
}

.DTN0qG_stone {
  background-color: #2e2e2e;
}

.DTN0qG_metal {
  background-color: #b3b3b3;
}

.DTN0qG_obsidian {
  background-color: #000;
}

.DTN0qG_grass {
  background-color: #085e00;
}

.DTN0qG_bronze {
  background-color: #c75a00;
}

.DTN0qG_jade {
  background-color: #0f8;
}

.DTN0qG_gold {
  background-color: #d6af00;
}

.DTN0qG_ruby {
  background-color: #e7103e;
}

.DTN0qG_sapphire {
  background-color: #008cff;
}

.DTN0qG_amethyst {
  background-color: #b331ff;
}

.DTN0qG_diamond {
  background-color: #fff;
}

.DTN0qG_no {
  background-color: #640000;
}

.DTN0qG_yes {
  background-color: #006400;
}

#DTN0qG_timer {
  text-align: center;
  color: #fff;
  margin-bottom: 24px;
  font-size: 40px;
}

#DTN0qG_disconnected {
  justify-content: center;
}

#DTN0qG_mint {
  text-align: center;
  color: #fff;
  image-rendering: pixelated;
  min-width: 450px;
  min-height: 600px;
  background-image: url("images/tennisCourt.png");
  background-size: cover;
  margin: 24px auto auto;
  font-size: 24px;
  left: 0;
  right: 0;
}

#DTN0qG_game {
  text-align: center;
  color: #fff;
  image-rendering: pixelated;
  min-width: 450px;
  min-height: 600px;
  background-image: url("Alleyway.aed1d7d1.png");
  background-size: cover;
  margin: 24px auto auto;
  font-size: 24px;
  left: 0;
  right: 0;
}

#DTN0qG_meme {
  text-align: center;
  color: #fff;
  image-rendering: pixelated;
  min-width: 450px;
  min-height: 600px;
  background-image: url("alleyway1.09efa342.png");
  background-size: cover;
  margin: 24px auto auto;
  font-size: 24px;
  left: 0;
  right: 0;
}

#DTN0qG_meme-canvas {
  width: 400px;
  height: 400px;
  cursor: pointer;
  background-color: #00000080;
  border: 5px solid #fff;
  margin: 0 50px 50px;
}

.DTN0qG_mint-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  display: flex;
}

.DTN0qG_status-text {
  align-items: center;
  margin-left: 8px;
  font-size: 18px;
  display: inline-flex;
}

.DTN0qG_info-text {
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 18px;
  display: inline-flex;
}

.DTN0qG_info-wrapper {
  width: 100%;
  text-align: left;
}

.DTN0qG_section {
  color: #fff;
  width: 200px;
  background-color: #00000040;
  border: 1px solid gray;
  margin: 20px;
  padding: 0 20px 20px;
  font-family: "Press Start 2P", sans-serif;
  position: absolute;
}

.DTN0qG_gameSection {
  color: #fff;
  width: 200px;
  background-color: #00000040;
  border: 1px solid gray;
  margin: 20px;
  padding-top: 0;
  font-family: "Press Start 2P", sans-serif;
  position: absolute;
}

.DTN0qG_button {
  opacity: .8;
  color: #fff;
  width: auto;
  height: 18px;
  cursor: pointer;
  background-color: #416;
  border: 1px solid gray;
  margin: 8px;
  padding: 16px;
  font-family: "Press Start 2P", sans-serif;
  font-size: 18px;
  display: inline-block;
}

.DTN0qG_arrow {
  padding: 16px 16px 24px;
}

.DTN0qG_disabled {
  color: gray;
  cursor: default;
}

.DTN0qG_row {
  flex-direction: row;
  place-content: center space-between;
  display: flex;
}

#DTN0qG_ethos-button, #DTN0qG_elements-button, #DTN0qG_faq-button, #DTN0qG_roadmap-button {
  cursor: pointer;
}

#DTN0qG_mint-header {
  margin-top: 32px;
  line-height: 1.5em;
}

#DTN0qG_clay-view {
  height: 500px;
  width: 500px;
  border: 1px solid gray;
  margin-top: 24px;
}

#DTN0qG_button-container {
  padding-top: 12px;
  display: flex;
}

span {
  display: inline-block;
}

.DTN0qG_socialIcon {
  width: 32px;
  height: 32px;
  padding: 25px;
}

.DTN0qG_nav-tabs {
  border-bottom: 1px solid #ddd;
}

.DTN0qG_nav {
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 100px);
  margin-top: 16px;
  padding-left: 0;
  list-style: none;
  display: grid;
}

.DTN0qG_inactive-tab {
  color: gray;
}

.DTN0qG_tab {
  width: 100px;
  cursor: pointer;
  border: 1px solid gray;
  margin: 5px 5px -12px;
  padding: 8px;
  font-size: large;
  display: block;
}

@media only screen and (max-width: 1000px) {
  #DTN0qG_ethos-button, #DTN0qG_elements-button, #DTN0qG_faq-button, #DTN0qG_roadmap-button {
    display: block;
  }

  .DTN0qG_nav {
    display: grid;
  }

  .DTN0qG_info-text {
    font-size: 14px;
  }

  #DTN0qG_clay-view {
    height: 70vw;
    width: 70vw;
  }

  #DTN0qG_mint-header {
    margin-top: 48px;
  }

  #DTN0qG_mint-count {
    margin-bottom: 32px;
  }

  #DTN0qG_timer {
    font-size: 30px;
  }

  .DTN0qG_topLogo {
    min-width: 0;
  }

  .DTN0qG_socialIcon {
    width: 24px;
    height: 24px;
    padding: 20px;
  }
}

/*# sourceMappingURL=index.91f0c245.css.map */
